import React, { useEffect } from 'react';
import { LoginForm } from 'src/sections';

function LoginPage() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

export default LoginPage;
